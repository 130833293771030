import React from "react"
import Obfuscate from "react-obfuscate"

const emailAddress = "operationsmanager@alfaconsulting.services"

export default function BottomLine() {
  return (
    <p className="bottomline" data-in-effect="bounceInDown">
      For any inquiries please contact <Obfuscate email={emailAddress} />
    </p>
  )
}
