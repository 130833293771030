import React from "react"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import ContentWrapper from "@sections/ContentWrapper"
import BottomLine from "@components/BottomLine"

const title = "404 / Nothing found"

export default function Home() {
  return (
    <Layout pageClass="error-page">
      <SEO title={title} error />
      <ContentWrapper>
        <h1 className="glow in page-tlt">404</h1>
        <p className="page-tlt" data-in-effect="bounceInDown">
          NOT FOUND
        </p>
      </ContentWrapper>
      <BottomLine />
    </Layout>
  )
}
